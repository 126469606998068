import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "./enquiry.css"
import API from "../api";

const Enquiry = () => {
  const api = API()
  const [t, _] = useTranslation()
  const [isError, setIsError] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [thanksMsg, setThanksMsg] = useState("")
  const [form, setForm] = useState({firstname:"",email:""})

  const update = (e) => {
    let newForm = {...form}
    newForm[e.target.name] = e.target.value
    setForm(newForm)
    setErrorMsg("")
    setIsError(false)
  }
  const validate = () => {
    return (
      form.email.match(/^\S+@\S+\.\w+$/) &&
      form.firstname.match(/^\w+$/)
    )
  }
  const displayThanks = () => {
    setThanksMsg(t('enquiry.thanks'))
    setIsDone(true)
  }
  const displayDuplicate = () => {
    setThanksMsg(t('enquiry.duplicate'))
    setIsDone(true)
  }
  const sendForm = async () => {
    let isRegistered = await api.sendMarketingEnquiry(form);
    if(isRegistered) displayThanks();
    else displayDuplicate();
  }
  const onSubmit = async (e) => {
    if(validate()) {
      setErrorMsg("")
      setIsError(false)
      await sendForm();
    } else {
      setIsError(true)
      setErrorMsg(t('enquiry.errorMsg'))
    }
  }
  return <div>
    <div className={`enquiryForm done-${isDone}`} onChange={update}>
        <div><p>{t('enquiry.info')}</p></div>
        <div className={`error ${isError}`}>{errorMsg}</div>
        <div><input placeholder={t('enquiry.firstname')} name="firstname" pattern="\w+"/></div>
        <div><input placeholder={t('enquiry.email')} name="email" pattern="\S+@\S+"/></div>
        <div>
          <button type="submit" onClick={onSubmit}>{t('enquiry.send')}</button>
        </div>
      <div className="thanks">
        {thanksMsg}
      </div>
    </div>

  </div>
}

export default Enquiry