import balanceCheck from "./screens/balance-check";

const API = (baseUrl = '') => {
    baseUrl = baseUrl || (window.GIFTSTARR_CFG && window.GIFTSTARR_CFG.API_URL) || '/api'


    const doFetch = async (url, init=null) => {
        let result;
        let response;
        try {
            response = await fetch(baseUrl + url, init);
            if(response.ok) {
                try {
                    result = await response.json()
                } catch (e){
                    result = {error: e.toString()}
                }
            } else {
                result = {error: response.status}
            }
        } catch (e) {
            result = {error: e.toString()};
        }
        return result
    }

    const get = async (url) => {
        return doFetch(url);
    }

    const del = async (url) => {
        const init = {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer"
          };
        return doFetch(url, init);
    }

    const post = async (url, data) => {
        const init = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data), // body data type must match "Content-Type" header
          };
        return doFetch(url, init);
    }

    const getGiftInfo= async (code) => {
        if(!code) return {error:"missing gift code"}
        return get('/getGiftInfo/'+code)
    }

    const scheduleEmailToBeneficiary = async (code, giftInfoUpdate={}) => {
        if(!code) return {error:"missing gift code"}
        return post('/sendVideoMessage/' + code, giftInfoUpdate)
    }

    const balanceCheck = async (token) => {
        return get('/balance/' + token);
    }

    const cardStatement = async (token) => {
        return get('/statement/' + token);
    }

    const uploadFiles = async (file, token, onProgress, onStart=null) => {
        const url = baseUrl + "/video/" + token;
        const promise = new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.upload.addEventListener('progress', e => onProgress(e.loaded / e.total));
            xhr.addEventListener('load', () => resolve({status: xhr.status, body: xhr.responseText}));
            xhr.addEventListener('error', () => reject(new Error('File upload failed')));
            xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
            xhr.addEventListener('timeout', () => reject(new Error('File upload timed out')));
            xhr.timeout = 60000;
            xhr.open('POST', url, true);
            const formData = new FormData();
            //Array.from(files).forEach((file, index) => formData.append(index.toString(), file));
            formData.append("file", file);
            console.log(formData);
            if(onStart) onStart(xhr);
            xhr.send(formData);
        });
        return promise;
    }

    const getVideoUrl = async (token) => {
        return get("/video/" + token)
    }

    const deleteVideo = async (token) => {
        return del("/video/" + token)
    }

    const sendMarketingEnquiry = async (form) => {
        return post("/marketing/enquiry", form);
    }

    return {
        getGiftInfo  : getGiftInfo,
        scheduleEmailToBeneficiary: scheduleEmailToBeneficiary,
        balanceCheck : balanceCheck,
        cardStatement : cardStatement,
        uploadFiles : uploadFiles,
        getVideoUrl : getVideoUrl,
        deleteVideo : deleteVideo,
        sendMarketingEnquiry : sendMarketingEnquiry
    }
}

export default API